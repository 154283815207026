<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-chip
        v-on="on"
        :ripple="false"
        :color="chipColor"
        :title="title"
        :label="square"
        :x-small="isShortDate"
        :class="[
          textClass,
          spacingClass,
          { 'is-editable': editable }
        ]"
        class="elevation-0"
        outlined
      >{{ formattedDate }}</v-chip>
    </template>

    <v-date-picker
      :value="datepickerValue"
      @input="showDatePicker = false"
      @change="emitChangeEvt"
    />
  </v-menu>
</template>

<script>
import { dateToYYYYMMDD } from '@/filters/dateToYYYYMMDD'
import MONTHS from '@/enums/months'

const DATE_FORMAT_LONG = 'long'
const DATE_FORMAT_SHORT = 'short'

export default {
  name: 'AppDatePicker',
  props: {
    date: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    square: {
      type: Boolean,
      default: false
    },
    dateStyle: {
      type: String,
      default: DATE_FORMAT_LONG
    },
    color: {
      type: String,
      default: ''
    },
    spacingClass: {
      type: String,
      default: 'ml-1 mr-1'
    },
    textClass: {
      type: String,
      default: ''
    },
    defaultText: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDatePicker: false
    }
  },
  computed: {
    datepickerValue () {
      return this.date || dateToYYYYMMDD()
    },
    formattedDate () {
      // show placeholder if no date provided but placeholder
      if (!this.date && this.defaultText) return this.defaultText

      const arr = this.datepickerValue.split('-')
      const monthNum = parseInt(arr[1], 10) - 1
      const monthName = MONTHS[monthNum]

      return `${arr[2]} ${this.isShortDate ? monthName.substring(0, 3) : monthName} ${this.isShortDate ? '' : arr[0]}`
    },
    chipColor () {
      if (this.color) return this.color
      return this.disabled ? '#777' : '#333'
    },
    isShortDate () {
      return this.dateStyle === DATE_FORMAT_SHORT
    }
  },
  methods: {
    emitChangeEvt (date) {
      this.$emit('change', date)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-editable {
  border-color: transparent !important;
  border-bottom: 1px dotted #000 !important;
  border-radius: 0px;
}
</style>
